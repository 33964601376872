import React from 'react';
import TaskManager from '../containers/TaskManager';

const Main = () => {
    return (
        <>
          <TaskManager />
        </>
    )
}


export default Main;

