import React from 'react';

const Footer = () => {
    return (
        <>
         <footer>
            <p>&copy; {new Date().getFullYear()} Your Website. All rights reserved.</p>
         </footer>
        </>
    )
}

export default Footer;

